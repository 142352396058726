import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Filter, Props as FilterProps } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  offers: OfferProps[]
}

export const OffersList = memo(function OffersList({
  filters,
  languageCode,
  offers,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {filters && filters?.length > 0 ? (
        <FadeInUp>
          <Filters dial={2} inline row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              label={useVocabularyData('all', languageCode)}
              onClick={() => setFilteredCategory('all')}
            />
            {uniqBy(filters, 'category').map((item, index) => (
              <Filter
                {...item}
                active={filteredCategory === item.category}
                key={index}
                onClick={(e) => {
                  setFilteredCategory(e.target.getAttribute('data-category'))
                }}
              />
            ))}
          </Filters>
        </FadeInUp>
      ) : null}

      <Offers row stretch wrap>
        {offers.map((item, index) => (
          <Offer
            key={index}
            className={
              filteredCategory === item.category || filteredCategory === 'all'
                ? 'visible'
                : undefined
            }
            {...item}
          />
        ))}
      </Offers>
    </Container>
  )
})

const Container = styled.section`
  margin: 4.75rem auto 8.125rem;
  padding: 0 6.667vw;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
    padding: 0 1.875rem;
  }
`

const Filters = styled(FlexBox)``

const Offers = styled(FlexBox)`
  margin-right: -5.556vw;

  @media (max-width: 1023px) {
    margin-right: -1.875rem;
  }
`
