import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  className?: string
  discount?: string
  image?: ImageProps
  label?: string
  languageCode: string
  title: string
  URL: string
  validity?: string
}

export const Offer = memo(function Offer({
  category,
  className,
  discount,
  image,
  label,
  languageCode,
  title,
  URL,
  validity,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container
      className={className}
      data-category={category || undefined}
      to={URL}
    >
      <FadeInUp>
        {image ? <LazyLoadImage {...image} /> : null}
        {discount ? <Discount>{discount}</Discount> : null}
        <Wrapper>
          {category ? <Category>{label}</Category> : null}
          <Title>{title}</Title>
          {validity ? <Validity>{validity}</Validity> : null}
        </Wrapper>
        <CTA className="offer-cta">
          {useVocabularyData('discover-offer', languageCode)}
        </CTA>
      </FadeInUp>
    </Container>
  )
})

const Container = styled(Link)`
  display: none;
  width: calc(50% - 5.556vw);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 4.8125rem 5.556vw 0 0;
  text-align: left;
  &.visible {
    display: block;
  }

  > div {
    overflow: hidden;
    padding-bottom: 70%;
    position: relative;
    &:hover {
      img {
        transform: scale(1.1);
      }
      .offer-cta {
        opacity: 1;
        &:after {
          right: 0;
        }
      }
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 1.875rem);
    margin: 3.75rem 1.875rem 0 0;
    > div {
      height: 100%;
      padding: 5.625rem 1.875rem 1.875rem;
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - 1.875rem);
    margin-top: 1.875rem;
    margin-right: 0;
  }
`

const Discount = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding: 1.5625rem 2.0625rem 1.5625rem 2.9375rem;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  text-transform: lowercase;
  z-index: 3;

  @media (max-width: 1023px) {
    font-size: 1rem;
    padding: 1.125rem 1.5rem;
  }
`

const Wrapper = styled.div`
  padding-left: 2rem;
  position: absolute;
  top: 50%;
  right: 4.167vw;
  left: 4.167vw;
  transform: translateY(-50%);
  z-index: 2;
  &:before {
    content: '';
    width: 0.125rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    transform: none;
  }
`

const Category = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 2.1875rem;
`

const Validity = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  margin-top: 0.625rem;
`

const CTA = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  opacity: 0;
  padding: 0 0.375rem;
  position: absolute;
  bottom: 3.125rem;
  left: 4.167vw;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    bottom: -0.375rem;
    right: 100%;
    left: 0;
    transition: 0.3s 0.1s ease-in-out;
  }

  @media (max-width: 1023px) {
    display: inline-block;
    opacity: 1;
    margin-top: 3rem;
    position: relative;
    bottom: auto;
    left: auto;
    &:after {
      right: 0;
    }
  }
`
